import ClockPerMarket from "./ClockPerMarket";
import React from "react";
import {Link} from "../../../utils/url-optimizer";
import { handleMarketLink } from "../../../utils/handleMarketLink";

const SwissTimeZone = () => {
  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-white single-footer-widget pb-4 pb-lg-0">
      <div className="text-white">
        <h6 className="footer-market-title pb-2 pb-sm-4 text-center text-sm-start">
          <b>
            <Link
              to="/ch/de"
              onClick={evt => handleMarketLink(evt, "/ch/de")}
              rel="noreferrer"
              className="text-white"
            >
              Switzerland
            </Link>
            <span className="ps-2 d-none d-lg-inline-block">
              <Link
                to="/ch/de"
                onClick={evt => handleMarketLink(evt, "/ch/de")}
                rel="noreferrer"
                className="footer-link-gray ps-2"
              >
                DE
              </Link>
              <Link
                to="/ch/en"
                onClick={evt => handleMarketLink(evt, "/ch/en")}
                rel="noreferrer"
                className="footer-link-gray ps-2"
              >
                EN
              </Link>
              <Link
                to="/ch/fr"
                onClick={evt => handleMarketLink(evt, "/ch/fr")}
                rel="noreferrer"
                className="footer-link-gray ps-2"
              >
                FR
              </Link>
              <Link
                to="/ch/it"
                onClick={evt => handleMarketLink(evt, "/ch/it")}
                rel="noreferrer"
                className="footer-link-gray ps-2"
              >
                IT
              </Link>
            </span>
          </b>
        </h6>
        <Link
            to="/ch/de"
            onClick={evt => handleMarketLink(evt, "/ch/de")}
            rel="noreferrer"
            className="text-white"
        >
        <ClockPerMarket
          timeZoneName="Europe/Zurich"
          address1="Gerbergasse 5"
          address2="8001 Zürich"
          country="Switzerland"
        />
        </Link>
      </div>
    </div>
  );
};

export default SwissTimeZone;
