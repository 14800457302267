import React from "react";
import logo from "../../assets/images/tp24-symbol.png";

import AmsterdamTimeZone from "./footer/AmsterdamTimeZone";
import UKTimeZone from "./footer/UKTimeZone";
import AustralianTimeZone from "./footer/AustralianTimeZone";
import SwissTimeZone from "./footer/SwissTimeZone";
import SocialMediaLinks from "./footer/SocialMediaLinks";
import {Link} from "../../utils/url-optimizer";

const Footer = props => {
  const {
    footerTitle,
    facebookUrl,
    twitterUrl,
    linkedInUrl,
    youtubeUrl,
    allRightsReserved,
    privacyPolicyText,
    privacyPolicyURL,
    termsAndConditionsText,
    termsAndConditionsURL,
    complaintsText,
    complaintsURL,
    certificationLogo,
    certificationLogoText,
    footerText
  } = props;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-black">
      <div className="container">
        <h3 className="text-white footer-title text-center text-lg-start">
          {footerTitle}
        </h3>
        <div className="row d-flex pb-100 ">
          {/* <div className="row d-none d-md-flex pb-100"> */}
          <SwissTimeZone />
          <AustralianTimeZone />
          <UKTimeZone />
          <AmsterdamTimeZone/>
        </div>

        <div className="row pb-4 d-flex align-items-end">
          <div className="col-sm-5 col-lg-3 ">
            <div className="single-footer-widget">
              <a href="/" className="logo" aria-label="Logo">
                <img src={logo} alt="logo" height="174px"width="295px" loading="lazy"/>
              </a>
            </div>
          </div>

          <SocialMediaLinks facebookUrl={facebookUrl} linkedInUrl={linkedInUrl} twitterUrl={twitterUrl} youtubeUrl={youtubeUrl}/>
          <div className="col-lg-7 col-sm-12 ">
            {certificationLogo && (
              <div className="single-footer-widget footer-menu footer-certification-menu">
                <img
                  className="footer-certification-logo"
                  src={certificationLogo}
                  loading="lazy"
                />
                <p className="text-white footer-certification-text">
                  {certificationLogoText}
                </p>
              </div>
            )}
            <div className="single-footer-widget footer-menu">
              <p className="pe-4 text-white">
                © {currentYear} {allRightsReserved}
              </p>
              {privacyPolicyText && privacyPolicyURL && (
                <p className="pe-4 ">
                  <Link to={privacyPolicyURL}>{privacyPolicyText}</Link>
                </p>
              )}

              {termsAndConditionsText && termsAndConditionsURL && (
                <p className="pe-4 ">
                  <Link to={termsAndConditionsURL}>
                    {termsAndConditionsText}
                  </Link>
                </p>
              )}
            </div>
          </div>

          <div className="footer-bottom-area">
            <p className="">{footerText}</p>
            {complaintsText && complaintsURL && (
              <div className="pt-2">
                <p className="text-white">
                  <Link className="text-white" to={complaintsURL}>
                    {complaintsText}
                  </Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
