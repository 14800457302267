import React from "react";

const SocialMediaLinks = ({
  facebookUrl,
  twitterUrl,
  linkedInUrl,
  youtubeUrl,
}) => {
  return (
    <div className="col-sm-7 col-lg-2 ">
      <div className="single-footer-widget pl-5">
        <ul className="social-link text-nowrap">
          {facebookUrl && (
            <li>
              <a
                href={facebookUrl}
                className="d-block"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook URL"
              >
                <i className="bx bxl-facebook"></i>
              </a>
            </li>
          )}
          {twitterUrl && (
            <li>
              <a
                href={twitterUrl}
                className="d-block"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter URL"
              >
                <i className="bx bxl-twitter"></i>
              </a>
            </li>
          )}
          {linkedInUrl && (
            <li>
              <a
                href={linkedInUrl}
                className="d-block"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn URL"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </li>
          )}
          {youtubeUrl && (
            <li>
              <a
                href={youtubeUrl}
                className="d-block"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube URL"
              >
                <i className="bx bxl-youtube"></i>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SocialMediaLinks;
