import React from "react";
import Footer from "../../components/global/Footer";

const FooterPreview = ({ entry }) => {
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
      <>
        <Footer {...data} />
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default FooterPreview;
