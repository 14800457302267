import React from "react";
import { Link as GatsbyLink } from 'gatsby';

export const Link = (props) => {
    const {
        to,
        children,
        ...rest
    } = props;

    // here we add the trailing slash for every URL we put into a link
    const toWithSlash = to?.endsWith('/') ? to : `${to}/`;
    return (
        <GatsbyLink
            to={toWithSlash}
            {...rest}
        >
            {children}
        </GatsbyLink>
    );
};