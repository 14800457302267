import React from "react";
import ClockPerMarket from "./ClockPerMarket";
import {Link} from "../../../utils/url-optimizer";
import { handleMarketLink } from "../../../utils/handleMarketLink";
import MarketName from "./MarketName";

const UKTimeZone = () => {
  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-white single-footer-widget pb-4 pb-lg-0">
      <Link
        to="/uk"
        onClick={evt => handleMarketLink(evt, "/uk")}
        rel="noreferrer"
        className="text-white"
      >
        <MarketName countryName="United Kingdom" countryCode="EN" />
        <ClockPerMarket
          timeZoneName="Europe/London"
          address1="77 Charlotte St"
          address2="London W1T 4PW"
          country="United Kingdom"
          officeAddress="125 Deansgate, Manchester M3 2BY"
        />
      </Link>
    </div>
  );
};

export default UKTimeZone;
