import React from "react";

const MarketName = ({ countryName, countryCode }) => {
  return (
    <h6 className="footer-market-title pb-2 pb-sm-4 text-center text-sm-start">
      <b>
        {countryName}
        <span className="ps-2 d-none d-lg-inline-block">
          <a rel="noreferrer" className="footer-link-gray ps-2">
            {countryCode}
          </a>
        </span>
      </b>
    </h6>
  );
};

export default MarketName;
