import ClockPerMarket from "./ClockPerMarket";
import React from "react";
import {Link} from "../../../utils/url-optimizer";
import { handleMarketLink } from "../../../utils/handleMarketLink";

const AustralianTimeZone = () => {
  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-white single-footer-widget pb-4 pb-lg-0">
      <Link
        to="/au"
        onClick={evt => handleMarketLink(evt, "/au")}
        rel="noreferrer"
        className="text-white"
      >
        <h6 className="footer-market-title pb-2 pb-sm-4 text-center text-sm-start">
          <b>
            Australia
            <span className="ps-2 d-none d-lg-inline-block">
              <a rel="noreferrer" className="footer-link-gray ps-2">
                EN
              </a>
            </span>
          </b>
        </h6>
        <ClockPerMarket
          timeZoneName="Australia/Melbourne"
          address1="Level 2/696 Bourke St"
          address2="Melbourne VIC 3000"
          country="Australia"
        />
      </Link>
    </div>
  );
};

export default AustralianTimeZone;
